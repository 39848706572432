import { DomainTheme } from 'components/patterns/themes/context/ThemeProvider'
import { colors } from 'parameters/ui'
import { Optional } from 'lib'
import { SearchWithExtras } from 'generated/api'
import { useEffect, useState } from 'react'
import { DataWrapper } from 'components/patterns/suspense/internals/types'

type SearchSectionMode = 'loanPurposes' | 'pastSearch' | 'loading'

interface CtaConfig {
	sectionTitle: string
	tileTitle: string
	themeOverride: DomainTheme
}

export const cnfSearchRedirectLink = {
	search:
		process.env.NEXT_PUBLIC_CNF_SEARCH_REDIRECT_LOANS ||
		'https://fiona.com/network/fiona/cnf-loans/search/loan-purpose',
	slr: process.env.NEXT_PUBLIC_CNF_SEARCH_REDIRECT_SLR || 'https://moneylion.com/network/fiona/loans-slr/search',
	alr: process.env.NEXT_PUBLIC_CNF_SEARCH_REDIRECT_ALR || 'https://moneylion.com/network/fiona/loans-alr/search'
}

export const ctaConfigs: Record<SearchSectionMode, CtaConfig> = {
	loanPurposes: {
		themeOverride: {
			card: { background: { type: 'color', value: colors.primaryGreen1 } },
			icon: { primaryFill: colors.white },
			text: { color: colors.white }
		},
		tileTitle: 'More Loan Purposes',
		sectionTitle: "What's the reason for the loan?"
	},
	pastSearch: {
		themeOverride: {
			card: { background: { type: 'color', value: colors.white } },
			text: { color: colors.black }
		},
		tileTitle: 'Start New Search',
		sectionTitle: 'Pick up where you left off'
	},
	loading: { tileTitle: '', themeOverride: {}, sectionTitle: '' }
}

const modeFromResult = (value: Optional<SearchWithExtras>): SearchSectionMode => {
	if (value != undefined) return 'pastSearch'
	return 'loanPurposes'
}

export interface PendingSearchProps {
	data: DataWrapper<Optional<SearchWithExtras>>
}

export const useSearchDatawrapperState = (dataWrapper: DataWrapper<Optional<SearchWithExtras>>): SearchSectionMode => {
	const [state, setState] = useState<SearchSectionMode>('loanPurposes')
	useEffect(() => {
		// if (!isFirstVisit('apply')) setState('loading')
		dataWrapper
			.onComplete()
			.then(value => setState(modeFromResult(value)))
			.catch(() => setState('loanPurposes'))
	}, [dataWrapper])
	return state
}
