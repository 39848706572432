import { ROUTES } from 'parameters/index'
// TODO: We'll probably create ui/icons folder at some point to hold icons that repeat a lot
// e.g. ui/icons/FionaLogo/index.tsx
import {
	CreditCardsIcon,
	CreditScoreIcon,
	InsuranceIcon,
	LearnIcon,
	PersonalLoansIcon,
	SavingsIcon
} from 'components/layout/icons'
import { Device, NavContext } from './NavContext'
import { cnfSearchRedirectLink } from 'experiences/explore/loans/components/NewLoanSearchWidget/config'

type ConditionTest = (context: NavContext) => boolean

interface NavNodeAlternative {
	external?: boolean
	href: string
	parameters?: string
	condition: ConditionTest
}

export interface NavNode {
	name: string
	key: string
	icon?: JSX.Element
	href: string
	external?: boolean
	parameters?: string
	children?: NavNode[]
	alternatives?: NavNodeAlternative[]
	visible?: ConditionTest
	hideByDevice?: Device
	isActive?: (path: string, node: NavNode) => boolean
}

export const PrimaryNavElements: NavNode[] = [
	{
		key: 'loans',
		name: 'Loans',
		href: ROUTES.LOANS,
		icon: <PersonalLoansIcon />,
		children: [
			{
				name: 'Personal Loans',
				key: 'personal-loans',
				href: ROUTES.PERSONAL_LOANS,
				alternatives: [
					{
						href: ROUTES.NATIVE_LOANS,
						condition: (context): boolean => context.nativeFeatureFlag || context.userStatus === 'loggedIn'
					}
				]
			},
			{
				name: 'Student Loan Refinance',
				key: 'slr',
				href: cnfSearchRedirectLink.slr,
				alternatives: [
					{
						href: cnfSearchRedirectLink.slr,
						condition: (context): boolean => context.userStatus === 'loggedIn'
					}
				]
			},
			{
				name: 'Auto Refinance',
				key: 'auto-refi',
				href: cnfSearchRedirectLink.alr
			},
			{ name: 'Mortgages', key: 'mortgage', href: ROUTES.MORTGAGE },
			{ name: 'Mortgage Refinance', key: 'mortgage-refi', href: ROUTES.MORTGAGE, parameters: 'refinance=true' }
		]
	},
	{
		key: 'insurance',
		name: 'Insurance',
		href: ROUTES.INSURANCE,
		icon: <InsuranceIcon />,
		children: [
			{
				name: 'Life Insurance',
				key: 'life-insurance',
				href: ROUTES.LIFE_INSURANCE,
				alternatives: [
					{
						href: ROUTES.NATIVE_LIFE_INSURANCE,
						condition: (context): boolean => context.userStatus === 'loggedIn'
					}
				]
			},
			{ name: 'Auto Insurance', key: 'auto-insurance', href: ROUTES.AUTO_INSURANCE, external: true }
		]
	},
	{
		key: 'credit-cards',
		name: 'Credit Cards',
		icon: <CreditCardsIcon />,
		href: ROUTES.CREDIT_CARDS
	},
	{
		key: 'savings',
		name: 'Savings',
		icon: <SavingsIcon />,
		href: ROUTES.SAVINGS
	},
	{
		key: 'credit-score',
		name: 'Credit Score',
		icon: <CreditScoreIcon />,
		href: ROUTES.CREDIT_SCORE_EXTERNAL,
		external: true,
		hideByDevice: 'mobile'
	},
	{
		key: 'learn',
		name: 'Learn',
		icon: <LearnIcon />,
		href: ROUTES.LEARN_HUB,
		isActive: path => path.indexOf('/learn/') === 0,
		children: [
			{
				name: 'Getting Started',
				href: ROUTES.LEARN_HUB,
				key: 'learn-home',
				hideByDevice: 'desktop'
			},
			{ name: 'About Loans', href: '/learn/products/loans', key: 'learn-loans' },
			{ name: 'About Mortgage', href: '/learn/products/mortgage', key: 'learn-mortgage' },
			{ name: 'About Insurance', href: '/learn/products/insurance', key: 'learn-insurance' },
			{ name: 'About Credit Cards', href: '/learn/products/credit-cards', key: 'learn-cc' },
			{ name: 'About Savings', href: '/learn/products/savings', key: 'learn-savings' },
			// { name: 'About Credit Scores', href: '/learn/products/credit-score', key: 'learn-credit' },
			{ name: 'Our Network', href: '/learn/partners', key: 'learn-partners' },
			{ name: 'All Topics', href: '/learn/topics', key: 'learn-topics' },
			{ name: 'Glossary', href: '/learn/glossary', key: 'learn-glossary' }
		]
	}
]

export const SecondaryNavigation: NavNode[] = [
	{
		key: 'home',
		name: 'Home',
		href: '/'
	},
	{
		key: 'dashboard',
		name: 'Dashboard',
		href: ROUTES.DASHBOARD,
		visible: (context): boolean => context.userStatus === 'loggedIn'
	},
	{
		key: 'credit-score',
		name: 'Credit Score',
		hideByDevice: 'desktop',
		href: ROUTES.CREDIT_SCORE_EXTERNAL,
		external: true
	},
	{
		key: 'about',
		name: 'About Fiona',
		href: ROUTES.ABOUT
	},
	{
		key: 'support',
		name: 'FAQ & Support',
		href: ROUTES.SUPPORT
	},
	{
		key: 'contact',
		name: 'Contact Us',
		href: ROUTES.CONTACT
	}
]
